.base-table .operation-buttons {
  --buttons-gap: var(--space-xs);

  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 calc(-1 * var(--buttons-gap));
}

.base-table .operation-icon-button--small {
  --btn-size: 24px;

  margin: 0 var(--buttons-gap);
  width: var(--btn-size);
  height: var(--btn-size);
  font-size: calc(0.8 * var(--btn-size));
}

.base-table .operation-icon-button_medium {
  --btn-size: 22px;

  margin: 0 var(--buttons-gap);
  width: calc(1.6 * var(--btn-size));
  height: var(--btn-size);
  font-size: calc(0.8 * var(--btn-size));
}

.base-table .operation-button--large {
  margin: 0 var(--buttons-gap);
  padding: var(--space-xs);
  max-width: 90px;
  line-height: 16px;
}
