.mat-progress-bar {
  .mat-progress-bar-buffer {
    background-color: var(--border-color);
  }

  .mat-progress-bar-fill::after {
    background-color: var(--primary-color);
  }
}

.mat-mdc-select-panel.mdc-menu-surface {
  border-radius: var(--radius);
}

.create-user-form .mdc-list-item__primary-text {
  font-size: 0.9em;
}

// Mat-selection-list

.project-roles-dialog .mat-pseudo-checkbox-checked,
.side-panel__edit-project-role .mat-pseudo-checkbox-checked {
  background: var(--primary-color);
}

.mat-mdc-menu-panel.mat-menu-before {
  min-height: 48px;
  border-radius: var(--radius);
}

.mat-mdc-chip-listbox .mat-mdc-chip-option {
  height: auto;
  border-radius: 0;

  .mat-mdc-chip-remove.mat-icon {
    min-width: 18px;
  }
}

.mat-mdc-option.mdc-list-item .mdc-list-item__primary-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2rem;
}

.mdc-list-item__content .mdc-list-item__primary-text {
  display: flex;
  align-items: center;
}

.mat-mdc-list-base.app-sidebar__navigation .mat-mdc-list-item {
  border-radius: 0;
}
