.dialog-actions {
  display: flex;
  justify-content: space-around;
}

.dialog-actions .dialog-actions__button {
  --button-width: 90px;

  min-width: var(--button-width);
}
