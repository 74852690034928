.port-properties-options
  atsdartw-values-list-control
  .values-list__input-container {
  display: grid;
  grid-template-columns:
    minmax(120px, 1fr) repeat(2, minmax(120px, 2fr))
    minmax(40px, 1fr);
  align-items: center;
  column-gap: var(--space-m);
}

.port-properties-options atsdartw-values-list-control .values-list__input {
  grid-column: 1 / 3;
}
