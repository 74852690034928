:root {
  /* Elements spacing. */
  --space-m: 16px;
  --space-xxs: calc(var(--space-xs) * 0.5);
  --space-xs: calc(var(--space-m) * 0.25);
  --space-s: calc(var(--space-m) * 0.5);
  --space-l: calc(var(--space-m) * 1.5);
  --space-xl: calc(var(--space-m) * 2.5);
  --close-icon-color: var(--faint-color);

  /* Table colors */
  --table-row-hover-color: var(--faint-light-color);
  --table-row-focus-color: var(--faint-light-color);
  --table-background-color: var(--primary-light-color);
  --table-border-color: var(--primary-light-color);

  /* Breadcrumb colors */
  --breadcrumb-item-color: rgb(0 0 0 / 0.6);
  --breadcrumb-trailing-item-color: rgb(0 0 0 / 0.9);

  /** Button size for table */
  --operation-button-size: 20px;

  /** For mat-form-field label text */
  --mat-form-field-outlined-label-text-populated-size: 1.4em;
}
