// Autocomplete with chips.

.chips-autocomplete.mdc-menu-surface.mat-mdc-autocomplete-panel {
  padding: 0;
}

.chips-autocomplete
  .mat-mdc-option:not(:hover).mdc-list-item--selected:not(
    .mat-mdc-option-active
  ) {
  background: none;
}
