.category-preview {
  padding: var(--space-xs);
}

.category-preview .category-selection__option {
  margin-left: var(--space-l);
}

.category-preview
  .mdc-list-item--with-leading-icon.mdc-list-item--with-one-line.category-selection__tutorial {
  height: 2rem;
}

.category-preview
  .mdc-list-item--with-leading-icon.mdc-list-item.category-selection {
  height: 2rem;
}

.category-preview
  .mdc-list-item--with-leading-icon
  .mdc-list-item__start.category-selection__tutorial-icon,
.category-preview
  .mdc-list-item--with-leading-icon
  .mdc-list-item__start.category-selection__icon,
.tutorial-preview .category-selection__tutorial-icon {
  --icon-size: 16px;

  width: var(--icon-size);
  height: var(--icon-size);
  font-size: var(--icon-size);
  color: var(--border-container-color);
  margin: 0 var(--space-s);
}

.tutorial-preview .category-selection__tutorial {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2rem;
}

.tutorial-preview .category-selection__tutorial-icon {
  margin: 0 var(--space-s);
}

.tutorial-preview .category-selection__tutorial-link {
  width: 200px;
  white-space: nowrap;
}
