:root {
  --font-size: 14px;
  --font-family: roboto, -apple-system, blinkmacsystemfont, "Segoe UI", oxygen,
    ubuntu, cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --background-color: white;
  --background-contrast-color: #333333;
  --background-light-contrast-color: #c2c2c2;
  --surface-background-color: #f2f2f2;
  --surface-dark-background-color: #111111;

  /* Auth pages */
  --auth-primary-color: #115b8a;
  --auth-font-accent-light-color: rgb(18 18 18 / 0.65);

  /* Brand colors */
  --primary-color: #333333;
  --primary-dark-color: #222222;
  --primary-light-color: #e5e5e5;
  --primary-contrast-color: white;
  --primary-dark-contrast-color: white;
  --primary-light-contrast-color: black;
  --accent-color: #2c7aff;
  --accent-dark-color: #2c7aff;
  --accent-light-color: #2c7aff;
  --accent-contrast-color: white;
  --accent-dark-contrast-color: white;
  --accent-light-contrast-color: black;
  --warn-color: #ff1a40;
  --warn-dark-color: #aa190f;
  --warn-light-color: #e4746c;
  --warn-contrast-color: white;
  --warn-dark-contrast-color: white;
  --warn-light-contrast-color: black;
  --info-color: #0c927c;
  --info-background-color: #d2f8f2;
  --faint-color: #7f7f7f;
  --faint-light-color: #f1efef;
  --radius: 0;
  --radius-s: 4px;
  --radius-m: 6px;
  --border-color: #a4a4a4;
  --backdrop-color: rgb(0 0 0 / 0.3);
  --border-container-color: var(--faint-color);
  --disabled-option: #f6f6f6;
}
