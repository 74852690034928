.app-table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid var(--table-border-color);
  background-color: var(--primary-contrast-color);
  color: var(--base-color);
}

.app-table thead tr {
  background-color: var(--table-background-color);
}

.app-table thead th {
  padding: var(--space-s);
  vertical-align: middle;
  text-align: left;
  font-size: 1rem;
  color: var(--primary-dark-color);
}

.app-table td {
  height: 3em;
  padding: var(--space-s);
  vertical-align: middle;
  border-left: 1px solid var(--table-border-color);
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-table td:first-of-type {
  border-left: none;
}
