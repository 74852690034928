h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.title-1 {
  font-size: 26px;
  line-height: 26px;
  letter-spacing: normal;
  font-weight: 400;
}

.title-2 {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: normal;
  font-weight: 400;
}

.caption-1 {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: normal;
}

.caption-2 {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: normal;
}
