/*
 Reference: This styles are taken from ats-dart-frontend-sales.
 URL: https://github.com/saritasa-nest/ats-dart-frontend-sales/blob/6746d4fb27ded1d8d21ed66143f199006f45edbb/projects/web/src/theme/material-custom-theme/elements/form-field.scss#L1
*/
.auth-form .mat-mdc-form-field.mat-form-field-appearance-outline {
  width: 100%;
  border-top: 1.5em solid transparent;

  .mat-mdc-text-field-wrapper {
    border-bottom-left-radius: var(--mdc-shape-small, var(--radius));
    border-bottom-right-radius: var(--mdc-shape-small, var(--radius));
  }

  .mat-mdc-form-field-error .validation-error-message {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .mat-mdc-form-field-error-wrapper {
    margin-top: var(--space-xs);
  }

  .mat-mdc-form-field-icon-suffix,
  .mat-mdc-form-field-icon-prefix {
    padding: 0 var(--space-s);

    & > .mat-icon {
      padding: 0;
    }

    .mat-mdc-icon-button,
    .mat-mdc-icon {
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
    }

    .mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
      border-radius: 50%;
    }
  }

  .mdc-notched-outline {
    .mat-mdc-floating-label {
      font-size: 1.15rem;
    }
  }

  .mdc-text-field--disabled {
    .mdc-notched-outline__notch {
      // We need to override the material style.
      border-top-color: rgb(0 0 0 / 0.06);
    }
  }

  .mat-mdc-form-field-error.mat-mdc-form-field-bottom-align::before,
  .mat-mdc-form-field-hint.mat-mdc-form-field-bottom-align::before {
    display: none;
  }

  .mat-mdc-form-field-infix {
    min-width: 50px;
    width: 100%;
    min-height: initial;
    padding-top: 1.3em;
    padding-bottom: 1.2em;
  }

  &.no-padding,
  &.no-label {
    .mat-mdc-text-field-wrapper {
      padding: 0 var(--space-s);
    }

    .mat-mdc-form-field-icon-suffix,
    .mat-mdc-form-field-icon-prefix {
      padding: 0;
    }

    .mat-mdc-form-field-icon-prefix {
      margin-right: var(--space-s);
    }

    .mat-mdc-form-field-subscript-wrapper {
      height: 0;
      min-height: 0;
    }

    .mat-mdc-form-field-infix {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  &.no-label {
    border-top: 0.8em solid transparent;
    border-bottom: 0.8em solid transparent;
  }

  &.no-padding {
    border-top-width: 0;
  }
}
