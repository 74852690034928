// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

// TODO (Material v15 migration): Use CSS custom properties for styling.
// Since Angular Material v15 CSS custom properties can't be used as theme options.
// See related GitHub issue: https://github.com/angular/components/issues/25981.
// Theme colors are defined in variables.css.

$web-primary-colors: (
  main: #333333,
  dark: #222222,
  light: #e5e5e5,
  contrast: (
    main: white,
    dark: white,
    light: black,
  ),
);

$web-accent-colors: (
  main: #2c7aff,
  dark: #2c7aff,
  light: #2c7aff,
  contrast: (
    main: white,
    dark: white,
    light: black,
  ),
);

/*
  Signature of mat-palette.
  mat.define-palette(
    scss variable with shape { key1: color, key2: color, key3: color, contrast: { ...repeat all keys } }, (example above)
    key of primary color,
    key of darken color,
    key of lighten color,
    (optional) key of primary contrast - default contrast[key_of_primary_color]
  )
*/
$web-primary: mat.m2-define-palette($web-primary-colors, main, dark, light);
$web-accent: mat.m2-define-palette($web-accent-colors, main, dark, light);

$web-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $web-primary,
      accent: $web-accent,
    ),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($web-theme);
