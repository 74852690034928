.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--background-color);
  padding: var(--space-m);
}

.page-header__title {
  font-weight: normal;
}

.filters-form {
  --form-field-width: 200px;
  --submit-btn-width: 150px;
  --form-elements-margin: var(--space-s);

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 calc(-1 * var(--form-elements-margin));
}

.filters-form-control {
  display: flex;
  align-items: center;
  margin: 0 var(--form-elements-margin);
  min-width: 100px;
}

.filters-form-control__label {
  margin-right: var(--form-elements-margin);
}

.filters-form-control .filters-form-control__field {
  width: var(--form-field-width);
  min-width: 100px;
}

.filters-form .filters-form__submit-btn {
  width: var(--submit-btn-width);
  margin: var(--space-xs) var(--form-elements-margin);
}

.page-header__buttons-container {
  --button-width: 150px;
  --buttons-margin: var(--space-s);

  display: flex;
  align-items: center;
  margin: 0 calc(-1 * var(--buttons-margin));
}

.page-header__buttons-container .page-header__button {
  max-width: var(--button-width);
  width: 100%;
  height: 36px;
  margin: 0 var(--buttons-margin);
}
