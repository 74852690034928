a.mat-mdc-button-base,
button.mat-mdc-button-base {
  border-radius: var(--radius);
}

.mat-mdc-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mat-mdc-icon-button.mat-primary:not(.mat-mdc-button-disabled) {
  background-color: var(--background-contrast-color);
  color: var(--primary-contrast-color);
}

.mat-mdc-icon-button.mat-primary.mat-mdc-button-disabled {
  background-color: var(--primary-light-color);
}
