div:not(.auth-form) .mat-mdc-form-field.mat-form-field-appearance-outline {
  width: 100%;
  border-top: 1.5em solid transparent;

  .mdc-text-field--focused .mdc-notched-outline {
    .mdc-notched-outline__notch {
      border-top-width: 2px;
    }

    .mat-mdc-floating-label {
      top: calc(0.5em - 1px);
      left: -1px;
    }
  }

  .mdc-notched-outline {
    .mdc-notched-outline__notch {
      // There is no other way to override this style.
      /* stylelint-disable-next-line declaration-no-important */
      width: 100% !important;
      border-radius: var(--radius);
      border-top-style: solid;
      border-top-width: 1px;
    }

    .mdc-notched-outline__leading {
      width: 0;
      border-radius: var(--radius);
    }

    .mdc-notched-outline__trailing {
      border-radius: var(--radius);
    }

    .mat-mdc-floating-label {
      left: 0;
      font-size: 1.4em;
    }
  }

  .mat-mdc-form-field-error.mat-mdc-form-field-bottom-align::before,
  .mat-mdc-form-field-hint.mat-mdc-form-field-bottom-align::before {
    display: none;
  }

  .mat-mdc-form-field-infix {
    min-width: 100px;
    width: 100%;
    min-height: 2.5em;
    padding: 0.7em 0;
    border-top: none;
  }

  .mat-mdc-form-field-flex {
    padding: 0 0.5em;

    .mdc-floating-label {
      top: 1.4em;
    }

    .mdc-floating-label--float-above {
      top: 0.5em;
    }
  }

  .mat-mdc-input-element {
    min-height: 1.5em;
    line-height: 1.5em;
  }

  .mat-mdc-text-field-wrapper {
    padding: 0;
  }

  .mat-mdc-form-field-label {
    color: var(--primary-dark-color);
    font-weight: 500;
  }

  .mat-mdc-form-field-subscript-wrapper {
    padding: 0 0.2em;
    margin-top: 0;
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: var(--space-xs);
  }

  .mat-mdc-form-field-hint {
    line-height: 1em;
  }

  .mat-mdc-form-field-icon-suffix .mat-mdc-icon-button {
    width: 1.7em;
    height: 1.7em;
  }

  &.no-padding {
    border-top-width: 0;

    .mat-mdc-form-field-subscript-wrapper {
      height: 0;
    }
  }

  &.no-label {
    border-top: 1em solid transparent;
    border-bottom: 1em solid transparent;

    .mat-mdc-form-field-subscript-wrapper {
      height: 0;
    }

    .mat-mdc-form-field-error .validation-error-message {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
