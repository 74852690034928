/* App-specific variables. */
@import "./variables.css";

/* Element-specific styles. */
@import "./elements/index.css";

/* You can add application-scoped global styles to this file, and also import other style files */

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: var(--line-clamp-lines-amount, 4);
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
