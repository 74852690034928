.mat-mdc-button-wrapper {
  font-weight: 400;
}

.mat-mdc-button-base.mat-mdc-icon-button {
  text-align: center;

  .mat-mdc-button-persistent-ripple {
    border-radius: var(--radius);
  }

  .mat-mdc-button-touch-target {
    width: 100%;
    height: 100%;
  }

  .mat-icon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: inherit;
    height: auto;
    font-size: inherit;
  }
}

.mat-mdc-stroked-button:not(.mat-mdc-button-disabled) {
  border-color: currentcolor;
}
