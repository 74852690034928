.inline-label {
  display: grid;
  grid-template-columns: 85px 1fr;
  grid-auto-flow: row;
  align-items: center;
  gap: var(--space-m);
  width: 100%;
}

.inline-label__text {
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-mdc-form-field-error.flex-mat-error {
  display: flex;
}
