.category-selection.mat-mdc-selection-list {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;

  /** This's a variable for disabling material component styles. */
  --mdc-list-list-item-disabled-label-text-opacity: 1;

  .group-items {
    margin-left: var(--space-l);

    .mdc-list-item {
      height: 2rem;
    }
  }

  .mdc-list-item {
    height: 3rem;
  }

  .category-selection__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    /* Loader should be above header. */
    z-index: 2;
  }

  .category-selection__list-item,
  .category-selection__header-list-item {
    background-color: var(--table-background-color);

    &.mat-mdc-list-item {
      height: 2.5em;
      padding: var(--space-s);
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .category-selection__body {
    height: 100%;
    overflow-y: auto;
  }

  .mat-mdc-list-item {
    --icon-size: 16px;

    padding: 0 var(--space-xs);

    .mat-mdc-icon-button {
      width: calc(1.8 * var(--icon-size));
      height: calc(1.8 * var(--icon-size));
      line-height: calc(1.8 * var(--icon-size));
      font-size: var(--icon-size);
    }

    .mat-icon {
      margin: 0 var(--space-s);
      width: var(--icon-size);
      height: var(--icon-size);
      font-size: var(--icon-size);
      line-height: var(--icon-size);
      color: var(--border-container-color);
    }

    .mat-mdc-icon-button .mat-icon {
      margin: 0;
    }
  }

  .category-selection__category-name {
    width: 100%;
    padding-left: var(--space-xs);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .category-selection__body-loading {
    margin: var(--space-s);
    text-align: center;
  }

  .mdc-list-item--disabled {
    .mdc-list-item__start,
    .mdc-list-item__content,
    .mdc-list-item__end {
      opacity: 1;
    }
  }
}

.category-selection-header__type-toggle .mat-button-toggle-appearance-standard {
  background-color: var(--primary-light-color);
  color: var(--primary-color);

  .mat-button-toggle-button .mat-button-toggle-label-content {
    line-height: 1;
    font-size: 1rem;
    font-weight: 500;
  }

  &.mat-button-toggle-checked {
    background-color: var(--primary-color);
    color: var(--primary-light-color);
  }
}

.organization-permissions-selection.mat-mdc-selection-list .mat-mdc-list-item {
  padding: 0;
}

.select-properties-dialog
  .list-item
  .mdc-list-item__start.mat-mdc-list-option-checkbox-before {
  margin-right: 0;
}
