.panel-footer {
  --footer-height: 80px;
  --button-width: 120px;

  display: grid;
  place-items: center center;
  grid-auto-flow: column;
  position: relative;
  min-height: var(--footer-height);
  border-top: 1px solid var(--border-color);
}

.panel-footer .panel-footer__button {
  width: var(--button-width);
}
