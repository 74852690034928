atsdartc-base-table {
  .mat-mdc-header-cell .mat-sort-header-content {
    text-align: left;
    line-height: 1.2rem;
    font-weight: 700;
  }

  th.mat-mdc-header-cell:first-of-type,
  td.mat-mdc-cell:first-of-type,
  td.mat-mdc-footer-cell:first-of-type {
    padding-left: var(--space-s);
  }

  th.mat-mdc-header-cell:last-of-type,
  td.mat-mdc-cell:last-of-type,
  td.mat-mdc-footer-cell:last-of-type {
    padding-right: var(--space-s);
  }
}

.components-table .cell_disabled {
  background-color: var(--disabled-option);
}
