.app-sidebar .mat-list-item-content {
  color: var(--primary-contrast-color);
}

.app-sidebar .mat-drawer-inner-container {
  overflow-x: hidden;
}

.mat-toolbar.app-toolbar {
  background-color: var(--background-color);
}

.app-toolbar__profile-menu .mat-mdc-menu-content:not(:empty) {
  padding: 0;
}

.mat-toolbar.breadcrumbs.mat-toolbar-single-row .xng-breadcrumb-root {
  color: var(--breadcrumb-item-color);
}

.mat-toolbar.breadcrumbs.mat-toolbar-single-row .xng-breadcrumb-trail {
  color: var(--breadcrumb-trailing-item-color);
}

.app-toolbar .xng-breadcrumb-root {
  font-size: var(--font-size);
}

.app-toolbar__profile-menu.mat-mdc-menu-panel {
  border-radius: var(--radius);
}

.add-property-menu .mat-mdc-menu-content:not(:empty) {
  /* Width should be the same as button width. */
  width: 150px;
  padding: 0;
}

.add-property-menu .mat-mdc-menu-item {
  padding: 0 var(--space-m);
}
