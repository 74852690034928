@import "./variables.css";
@import "./elements/index.css";

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  background: var(--background-color);
  color: var(--background-contrast-color);
  font-family: var(--font-family);
  font-size: var(--font-size);
}

atsdartw-user-roles-select .mat-mdc-list-base .mdc-list-item__primary-text,
atsdartw-user-project-roles-select
  .mat-mdc-list-base
  .mdc-list-item__primary-text {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: minmax(100px, 1fr) minmax(100px, 2fr);
  gap: var(--space-l);
  font-size: 1rem;
}

.role-description-tooltip.mdc-tooltip .mdc-tooltip__surface {
  max-width: 250px;
  padding: var(--space-xs) var(--space-s);
  background-color: var(--background-contrast-color);
  border-radius: var(--radius);
  font-size: var(--font-size);
}
