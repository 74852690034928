.radio-group-label {
  display: block;
}

.radio-group-label_required::after {
  content: "*";
}

.radio-group-buttons {
  display: grid;
  grid-template-columns: 100px 150px repeat(2, 100px);
}

.radio-group-buttons .radio-group-button:first-child {
  margin-left: calc(var(--space-s) * -1.5);
}

.part-form .radio-group-button .mdc-label {
  padding-left: 0;
}

atsdartw-user-project-roles-radio-buttons .mdc-label,
atsdartw-user-project-roles-radio-buttons .mdc-form-field,
atsdartw-user-roles-radio-buttons .mdc-label,
atsdartw-user-roles-radio-buttons .mdc-form-field {
  width: 100%;
}
