.toast-container .ngx-toastr {
  max-width: 450px;
  min-width: 300px;
  width: 100%;
  margin-left: var(--space-s);
  border-radius: var(--radius);
  background-color: var(--primary-dark-color);
  box-shadow: 0 0 6px var(--background-light-contrast-color);
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px var(--background-light-contrast-color);
}

.toast-container .ngx-toastr.toast-error {
  background-color: var(--warn-color);
}
